import { Link, Typography } from '@material-ui/core';
import React from 'react';

const PublicationManagerAbout = () => (
  <>
    <Typography>
      Az Országos Széchényi Könyvtár (OSZK) 2024-ben induló új kötelespéldányok
      szolgáltatása mindenki számára elérhető, elsődleges célja mégis az, hogy a lehető
      leggyorsabban tájékoztassa a könyvtárszakmát a nemzeti könyvtárba kerülő
      kötelespéldányok bibliográfiai adatairól.
    </Typography>
    <Typography>
      A magyarországi kötelespéldányok szolgáltatása a jelenleg is hatályos{' '}
      <b>
        <i>
          717/2020. (XII. 30.) Korm. rendelet a kiadványok kötelespéldányainak
          szolgáltatásáról, megőrzéséről és használatáról
        </i>
      </b>{' '}
      című jogszabály alapján történik, amely meghatározza azoknak a dokumentumok körét és
      példányszámát, amelyeket kötelespéldányként be kell szolgáltatni.
    </Typography>
    <Typography>
      Az induló szolgáltatási felületen jelenleg közel húszezer nyomtatott kiadvány
      (könyvek, füzetes kisnyomtatványok, fizikai hordozón megjelenő elektronikus
      dokumentumok) legfontosabb formai adatai (bibliográfiai metaadatok) érhetők el. A
      szolgáltatás hétről-hétre bővülő tartalommal újul meg, jelenleg a 2023-ban és
      2024-ben megjelent, illetve beszolgáltatott dokumentumok bibliográfiai adatait
      tartalmazza.
    </Typography>
    <Typography>
      Használata egyszerű: a keresést követően a találati listában megtekinthető a
      keresett kiadvány beérkezési dátuma, ISBN-száma (amennyiben rendelkezik ilyen
      azonosítóval), címadatai, a szerző/k neve, a kiadvány megjelenésének helye, éve és a
      kiadó neve.
    </Typography>
    <Typography>
      A felhasználó vármegyék szerint is kereshet, így a felület megteremti annak a
      lehetőségét is, hogy a vármegyei hatókörű könyvtárak a vármegyében megjelent és
      kötelespéldányként a nemzeti könyvtárba beszolgáltatott dokumentumok listájához
      szinte naprakészen hozzáférjenek.
    </Typography>
    <Typography>
      A gyors szolgáltatás miatt az adatbázisban található adatok nem validáltak, ezért
      azok rögzítési módjukban, esetenként tartalmukban, eltérhetnek a szabványos
      bibliográfiai adatoktól.
    </Typography>
    <Typography>
      A részletes, tárgyszavakkal is ellátott, szabványosan elkészített bibliográfiai
      leírásokat a felhasználók továbbra is elérhetik az OSZK katalógusában:{' '}
      <Link href="https://nektar2.oszk.hu/librivision_hun.html" target="_blank">
        NEKTÁR - Országos Széchényi Könyvtár
      </Link>{' '}
      vagy a Magyar Nemzeti Bibliográfia, Könyvek Bibliográfiája című elektronikus
      kiadvány számaiban:{' '}
      <Link href="https://www.oszk.hu/mnbwww/K/BIBLI.HTML" target="_blank">
        https://www.oszk.hu/mnbwww/K/BIBLI.HTML
      </Link>
    </Typography>
    <Typography align="center">
      Kérdéseiket, észrevételeiket a következő email-címen várjuk:
    </Typography>
    <Typography align="center">
      <Link href="mailto:koteles@oszk.hu">koteles@oszk.hu</Link>
    </Typography>
  </>
);

export default PublicationManagerAbout;
