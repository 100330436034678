import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from './index.module.scss';
import BasicLayout from '../layouts/basic_layout';
import Head from '../components/head';
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';
import PublicationManagerAbout from '../components/PublicationManager/PublicationManagerAbout';

const KotelespeldanySzolgaltatasrol = (props) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "A szolgáltatásról" } }) {
        frontmatter {
          title
          mainpage
        }
        html
      }
    }
  `);

  return (
    <React.Fragment>
      <BasicLayout>
        <Head title={data.markdownRemark.frontmatter.title} />
        <BreadcrumbsLayout
          menuItems={[
            { name: data.markdownRemark.frontmatter.mainpage, path: '/' },
            { name: 'A szolgáltatásról' },
          ]}
        >
          <div className={styles.textContentContainer}>
            <h3>A szolgáltatásról</h3>
            <PublicationManagerAbout />
          </div>
        </BreadcrumbsLayout>
      </BasicLayout>
    </React.Fragment>
  );
};

export default KotelespeldanySzolgaltatasrol;
